import Vue from 'vue'
import Router from 'vue-router'
import PageView from "@/view/PageView.vue";
import privacy from "@/view/privacy.vue"
import policy from "@/view/policy.vue"
import ContactMe from "@/view/ContactMe.vue"
import HomePage from "@/view/HomePage.vue"
import Software from "@/view/Software.vue"
import Service from "@/view/Service.vue"
import NewsInformation from "@/view/NewsInformation.vue"
import CompanyIntroduction from "@/view/CompanyIntroduction.vue"
import sSupport from "@/view/sSupport.vue"
Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '*',
      redirect: '/'
    }, {
      path: '/',
      name: 'PageView',
      component: PageView,
      children: [
        {
          path: "privacy",
          component: privacy,
          meta:{
            title: "隐私政策"
          }
        },
        {
          path: "policy",
          component: policy,
          meta:{
            title: "服务协议"
          }
        },
        {
          path: "contact",
          component: ContactMe,
          meta:{
            title: "联系我们"
          }
        },/*
        {
          path: "support",
          component: sSupport,
          meta:{
            title: "技术支持"
          }
        },*/
        {
          path: '/',
          redirect: '/home'
        }, {
          path: '/home',
          name: 'home',
          component: HomePage,
          meta: {
            title: '大谷物联科技有限公司'
          }
        }, {
          path: '/software',
          name: 'software',
          component: Software,
          meta: {
            title: '解决方案'
          }
        }, {
          path: '/service',
          name: 'service',
          component: Service,
          meta: {
            title: '我们的产品'
          }
        }, {
          path: '/newsinformation',
          name: 'newsinformation',
          component: NewsInformation,
          meta: {
            title: '软件服务'
          }
        }, {
          path: '/companyintroduction',
          name: 'companyintroduction',
          component: CompanyIntroduction,
          meta: {
            title: '公司介绍'
          }
        }
      ]
    }
  ]
})
