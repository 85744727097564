<template>
  <div id="app">

    <router-view/>
  </div>
</template>

<script>

export default {


  name: 'App'
}
</script>

<style>
.header-{
  height:89px ;
}
.header{
  height: 0px;
  display: none;
}
@media screen and (max-width: 997px){
  .header- {
    height: 51px;
  }
  .header{
    display: none;
    height: 6px;
  }
}
#app{
  overflow-x: hidden;
}
</style>
