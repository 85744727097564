var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"HomePage"}},[(!_vm.$route.meta.hideHeader)?_c('div',{staticClass:"header-",attrs:{"id":"headers"}},[_c('Header')],1):_vm._e(),_vm._m(0),_c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"row g-0 justify-content-center"},[_c('div',{staticClass:"col-lg-8 wow fadeInUp",staticStyle:{"visibility":"visible","animation-delay":"0.5s","animation-name":"fadeInUp"},attrs:{"data-wow-delay":"0.5s"}},[_c('p',{staticClass:"text-center mb-4",staticStyle:{"font-size":"30px"}},[_vm._v("提交反馈")]),_c('form',[_c('div',{staticClass:"row g-3"},[_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"col-12 text-center"},[_c('button',{staticClass:"btn btn-primary rounded-pill py-3 px-5",attrs:{"type":"submit"},on:{"click":_vm.message}},[_vm._v("提交")])])])])])])]),_vm._m(5),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"swiper"}},[_c('div',{staticClass:"swiper-container banner-swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy"}),_c('div',{staticClass:"swiper-lazy-preloader"}),_c('div',{staticClass:"swiper-slide-title"},[_c('h1',{staticClass:"gradient-text"},[_vm._v("联系我们")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-floating"},[_c('input',{staticClass:"form-control",staticStyle:{"font-size":"20px"},attrs:{"type":"text","id":"name","placeholder":"Your Name"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"form-floating"},[_c('input',{staticClass:"form-control",staticStyle:{"font-size":"20px"},attrs:{"type":"email","id":"email","placeholder":"Your Email"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-floating"},[_c('input',{staticClass:"form-control",staticStyle:{"font-size":"20px"},attrs:{"type":"text","id":"subject","placeholder":"Subject"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-floating"},[_c('textarea',{staticClass:"form-control",staticStyle:{"font-size":"25px","height":"200px"},attrs:{"placeholder":"Leave a message here","id":"message"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"row bigData-container"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn"},[_c('div',{attrs:{"id":"map"}})]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6"},[_c('div',{staticClass:"bigData-title"}),_c('h4',{staticClass:"bigData-device"},[_vm._v("大谷物联科技有限公司")]),_c('p',{staticClass:"contact"},[_vm._v(" 地址：深圳市龙岗区坂田街道雅星路8号星河双子塔-西塔1705B ")]),_c('p',{staticClass:"contact"},[_vm._v(" 邮箱：tangchenxi@daguiot.com ")]),_c('p',{staticClass:"contact"},[_vm._v(" 微信号: szdaguiot ")]),_c('p',{staticClass:"contact"},[_vm._v(" Tel：184-7617-9523 ")])])])])
}]

export { render, staticRenderFns }