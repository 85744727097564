<template>
    <div id="CompanyIntroduction">
      <div class="header-" id="headers">
        <Header></Header>
      </div>
        <div class="banner container-fuild text-center">关于我们</div>

        <div class="container">
          <div>
            <h1 style="padding: 30px 0px">智能视觉产品 全球解决方案领导者</h1>
            <p style="font-size: 17px">大谷物联让您可以快速轻松地创建自己的智能设备。从小型团队到服务全球用户，大谷物联让任何企业都可以轻松成长。我们的服务专注于基于视觉的IoT设备用例，将全球的品牌所有者，制造商，渠道商和最终用户联系在一起。我们的任务是利用一站式的软硬件和云端解决方案去构建，连接以及智能化IoT设备。大谷物联由来自各大研究机构和公司的AI，计算机视觉和软件/硬件设计专家团队组成。</p>
          </div>
            <div class="row CompanyIntroduction-container">
                <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
                    <img class="img-responsive center-block" src="@/assets/img/about_img.png">
                </div>
                <div class="col-xs-12 col-sm-12 col-md-6">
                    <h3 style="color: black;font-size: 30px;padding-bottom: 10px">大谷物联科技有限公司</h3>
                    <p class=".text-justify">大谷物联让您可以快速轻松地创建自己的智能设备。从小型团队到服务全球用户，大谷物联让任何企业都可以轻松成长。我们的服务专注于基于视觉的IoT设备用例，将全球的品牌所有者，制造商，渠道商和最终用户联系在一起。我们的任务是利用一站式的软硬件和云端解决方案去构建，连接以及智能化IoT设备。拓阔由来自各大研究机构和公司的AI，计算机视觉和软件/硬件设计专家团队组成。</p>
                    <p class=".text-justify">与国内众多厂商不同，大谷物联在成立之初，就把目光瞄向了机器视觉市场。产品聚焦于家庭安防、婴幼儿监护、个人娱乐、健康看护、远程教育、车载等多个领域，并建立自有的全球化云计算系统，其产品先后在全球多地销售，获得用户的广泛认可，为用户提供专业的智能视觉产品和整体解决方案。</p>
                </div>
            </div>
        </div>
      <Footer ></Footer>

    </div>
</template>
<script>
import { WOW } from 'wowjs';
export default {
    name: 'CompanyIntroduction',
    data(){
        return{

        }
    },
    mounted(){
      var wow = new WOW({
        boxClass: 'wow',
        animateClass: 'animated',
        offset: 0,
        mobile: true,
        live: true
      })
        wow.init();
    }
}
</script>
<style scoped>
#CompanyIntroduction{
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}
.banner{
    color: #fff;
    font-size: 30px;
    height: 150px;
    line-height: 150px;
    background-image: url('../assets/img/shouye.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: scroll;
    background-position: center center;
}
.row{
    margin-right: 0;
    margin-left: 0;
}
.CompanyIntroduction-container{
    padding: 100px 0;
    color: #808080;
    transition: all ease 0.5s;
}
.CompanyIntroduction-container>div>p{
    font-size: 15px;
    color: black;
    line-height: 2.5rem;
}
@media screen and (max-width: 997px){
    .CompanyIntroduction-container{
    padding: 10px 0;
    color: #808080;
}
}
</style>

