<template>
  <div class="privacy-policy">
    <h1>大谷云隐私政策</h1>
    <p>发布 / 更新日期：2023 年 11 月 24 日 生效日期：2023 年 11 月 24 日</p>
    <h2>一、我们收集哪些信息以及如何使用信息</h2>
    <h3>（一）您须授权我们收集和使用您个人信息的情形</h3>
    <p>收集个人信息旨在为您提供产品和 /
      或服务，并确保遵守相关法律。多数情况下，若您不提供特定信息，我们可能无法提供相应服务或回应问题。以下是具体功能及所需收集的信息：</p>
    <ul>
      <li><span class="info-collection">智能设备连接</span> 为提供大谷云服务，使您安全连接和管理智能设备，可能收集 Wi-Fi
        信息、IP 地址、位置信息、登录帐号信息、手机相关信息（包括硬件设备标识、手机型号、系统版本等）、智能设备相关信息以及大谷云帐号和智能设备的关联信息。这些信息用于提供智能设备快连、连接、发现附近设备和设备管理功能。
      </li>
      <li><span class="info-collection">登录帐号信息</span>：大谷云帐号、昵称、头像信息。</li>
      <li><span class="info-collection">手机相关信息</span>：硬件设备标识（imsi、imei、meid、设备硬件序列号、SIM 卡标识、OAID、Mac
        地址、Android ID）、手机型号、系统版本信息、系统语言、手机所设置的国家或地区、应用商店的版本号、手机屏幕尺寸及分辨率、CPU
        及显示设备相关信息。
      </li>
      <li><span class="info-collection">连接智能设备过程中收集的信息</span>：</li>
      <ul>
        <li>通过 Wi-Fi 连接的智能设备：Wi-Fi 信息（SSID,BSSID, Wi-Fi 的 Mac 地址、Wi-Fi 密码）、设备 Mac 地址、设备 ID。</li>
        <li>通过蓝牙建立本地连接后，通过 Wi-Fi 连接的智能设备：Wi-Fi 信息（SSID,BSSID, Wi-Fi 的 Mac 地址、Wi-Fi 密码）、设备
          Mac 地址、设备蓝牙 Mac 地址。
        </li>
        <li>通过蓝牙连接的智能设备：设备蓝牙 Mac 地址、设备 ID。</li>
        <li>通过 Zig-bee 方式连接的智能设备：设备 Mac 地址、设备 ID。</li>
      </ul>
      <li><span class="info-collection">智能设备的家庭管理</span> 可能收集您提供的<span class="info-collection">智能设备的房间设置信息</span>，用于构建智能设备的家庭管理，提升设备操作体验。
      </li>
      <li><span class="info-collection">设备共享</span> 支持您将智能设备通过深圳大谷帐号和微信分享给他人。可能收集您的帐号
        ID、分享帐号 ID、被分享的设备信息（设备 ID、设备名称、设备验证 key、设备的分享状态），用于提供设备分享功能及设备列表页展示分享状态。
      </li>
      <li><span class="info-collection">应用和智能硬件升级</span> 可能使用您大谷云应用的<span class="info-collection">版本信息、手机型号</span>提供大谷云应用升级服务；收集已连接的智能设备列表和<span
          class="info-collection">版本号信息</span>，提供智能设备升级功能。
      </li>
      <li><span class="info-collection">推荐智能产品</span> 可能使用您已绑定的智能设备信息，为您推荐能与已绑定设备进行智能联动的其他智能设备。
      </li>
      <li><span class="info-collection">智能场景（设备智能联动）</span> 支持您设定规则实现智能设备联动。可能收集您的<span
          class="info-collection">位置信息、智能场景规则设置、作息时间（MIUI 系统用户授权后同步系统作息时间设置）及设备状态</span>，以实现设备功能。此功能需您主动开启并设定规则。
      </li>
      <li><span class="info-collection">提供内容支持（例如文章、有声内容播放）</span>
        为您提供精选文章介绍智能设备相关内容，阅读时不收集信息。若您连接了支持内容播放的智能设备，可能收集您账号下绑定的智能设备信息，提供智能设备控制功能。
      </li>
      <li><span class="info-collection">提供云存储订购服务</span>
        实现下单交易功能，需收集您的订单信息、收货人姓名、收货地址、收货人手机号码，用于确定交易、支付结算、完成配送、查询订单及提供客服咨询与售后服务，还会判断交易是否异常以保障交易安全。支付功能由第三方支付平台（微信、支付宝）提供，需将订单号等信息与第三方共享以实现支付功能。
      </li>
      <li><span class="info-collection">消息推送服务</span> 使用您的 Android ID、IMEI、OAID 及 IP 地址进行链路调节，监听系统自启动广播，使用极光
        SDK 监听 App 的卸载和安装，相互促活被关闭的 SDK 推送进程，保障您及时接收消息。可通过设置关闭此功能。
      </li>
    </ul>
    <h3>（二）您可选择是否授权我们收集和使用您的个人信息的情形</h3>
    <p>为提供更好的服务，以下附加功能可能收集您的个人信息，若不提供，您仍可使用基础服务，但可能无法享受这些便利功能：</p>
    <ul>
      <li><span class="info-collection">人脸识别管理</span>
        若您连接了支持人脸识别功能的特定产品，可开启人脸识别功能并录入人脸素材。我们可能收集您主动反馈的问题视频，仅用于优化算法和模型。
      </li>
      <li><span class="info-collection">用户反馈</span> 可能收集您反馈的问题、反馈日志及<span class="info-collection">电话号码或邮箱</span>，用于了解问题并联系您。
      </li>
    </ul>
    <h3>（三）大谷云权限调用说明</h3>
    <p>
      在使用大谷云时，可能触发以下设备权限。我们会在权限申请时同步告知使用目的及不开启权限的影响，您可随时更改授权情况：</p>
    <ul>
      <li><span class="info-collection">读写外部存储空间（Android 版本）</span>：用于在扫一扫、用户反馈等功能中读写设备上的照片及文件。
      </li>
      <li><span class="info-collection">位置权限（Android、iOS 版本）</span>：用于扫描附近 WLAN
        列表、搜索附近蓝牙设备、智能场景中的自动化地理围栏等功能；当用户绑定不支持 GPS 的设备时，用于提供步频计算等功能。
      </li>
      <li><span class="info-collection">获取手机账户列表（MIUI 系统上）</span>：用于获取手机的账户列表以便快捷登录。</li>
      <li><span class="info-collection">相机权限（Android、iOS 版本）</span>：用于扫描二维码以进行设备安装、账号登录等功能。
      </li>
      <li><span class="info-collection">麦克风权限（Android、iOS 版本）</span>：当用户绑定摄像机、可视门铃等设备时，用于视频对讲、语音指令拾音等功能。
      </li>
      <li><span class="info-collection">读取电话权限（Android）</span>：用于在客户端本地生成设备 id。</li>
      <li><span class="info-collection">开启 WLAN 权限（Android、iOS 版本）</span>：用于快连设备时，连接设备发出的
        WIFI，以进行设备通信。
      </li>
      <li><span class="info-collection">开启蓝牙权限（Android、iOS 版本）</span>：用于快连设备时，连接设备发出的蓝牙信号，以进行设备通信；对于通过蓝牙连接的智能设备，也需要蓝牙权限用于通信。
      </li>
      <li><span class="info-collection">悬浮窗权限（Android 版本）</span>：当用户绑定摄像机，用于在悬浮窗中展示摄像机实时画面。
      </li>
      <li><span class="info-collection">消息推送权限（Android 版本）</span>：当发生事件告警时，通过消息渠道推送消息至用户手机。
      </li>
      <li><span class="info-collection">软件安装列表（Android、iOS 版本）</span>：用于关联启动的推送服务进行消息的推送和接收。
      </li>
      <li><span class="info-collection">修改语言配置（Android、iOS 版本）</span>：用于改变 App 中的语言显示。</li>
      <li><span class="info-collection">访问网络状态（Android、iOS 版本）</span>：通过对网络质量的判定优化视频通讯效率。</li>
      <li><span class="info-collection">访问 WiFi 状态（Android、iOS 版本）</span>：通过对网络质量的判定优化视频通讯效率。
      </li>
      <li><span class="info-collection">改变 WiFi 状态（Android、iOS 版本）</span>：当设备连接的网络发送变化时，需要使用该权限完成
        WiFi 网络的更改和配置。
      </li>
    </ul>
    <h3>（四）您充分知晓，以下情形中，我们收集、使用个人信息无需征得您的同意</h3>
    <p>
      与国家安全、国防安全有关的；与公共安全、公共卫生、重大公共利益有关的；与犯罪侦查、起诉、审判和判决执行等有关的；出于维护个人信息主体或其他个人的生命、财产等重大合法权益但又很难得到本人同意的；所收集的个人信息是个人信息主体自行向社会公众公开的；从合法公开披露的信息中收集的您的个人信息的，如合法的新闻报道、政府信息公开等渠道；根据您的要求签订合同所必需的；用于维护所提供的产品与
      / 或服务的安全稳定运行所必需的，例如发现、处置产品与 /
      或服务的故障；为合法的新闻报道所必需的；学术研究机构基于公共利益开展统计或学术研究所必要，且对外提供学术研究或描述的结果时，对结果中所包含的个人信息进行去标识化处理的。</p>
    <h3>（五）我们从第三方获得您个人信息的情形</h3>
    <p>在法律允许的情况下，我们可能从第三方处获得您的<span class="info-collection">个人信息</span>。例如，在智能场景（设备智能联动）功能中，在您授权的前提下，从智能设备处获得设备状态，以判断是否触发智能场景。您可在
      “智能” 页面下添加或删除智能联动规则，也可在智能设备页面中管理是否开启或删除智能联动。</p>
    <h3>（六）非个人信息</h3>
    <p>我们还可能收集其他<span class="info-collection">无法识别到特定个人的信息</span>，如用户操作行为等统计类数据。此类信息用于改善服务，被视为非个人信息。若与个人信息结合使用，则在结合期间视为个人信息。
    </p>
    <h2>二、我们如何使用 Cookie 和同类技术</h2>
    <p>大谷云和第三方服务供应商使用
      Cookie、标签和脚本等技术，用于分析趋势、管理网站、追踪用户活动并收集统计信息。我们会收到通过这些技术收集的分析报告，以更好地了解您的行为，为您提供更有用的信息。同时，我们还会收集日志文件、使用广告技术、移动分析软件和本地存储对象等，以提升服务质量。但我们将通过这些技术收集的信息视为非个人信息，除非当地法律将特定识别标记视为个人信息。</p>
    <h2>三、我们如何与第三方合作伙伴共享信息和使用方式</h2>
    <p>消息推送功能：不同手机品牌的推送 SDK 需要获取设备存储权限并收集唯一设备识别码，用于推送 / 下发通知栏消息。关联启动功能：大谷云
      APP 启动时会关联启动华为推送服务和极光推送服务；当华为推送服务和极光推送服务启动时，会关联启动大谷云 APP
      推送进程，用于消息的推送和接收。支付功能：第三方支付服务提供商（支付宝、微信支付）会获取设备存储权限并收集唯一设备识别码。诊断功能：com.qiniuSDK
      需要诊断相关服务器地址能否正常访问，便于定位问题。</p>
    <h2>四、保留政策</h2>
    <p>
      我们基于信息收集目的或遵守法律要求保留个人信息。在完成收集目的、确认您的删除或注销申请或终止运营相应产品或服务后，我们将停止保留，并做删除或匿名化处理。若出于公众利益等目的，我们将根据法律继续保留相关数据。</p>
    <h2>五、您的权利</h2>
    <h3>（一）控制设置</h3>
    <p>我们提供多种方式供您选择，以<span class="info-collection">限制收集、使用、披露或处理您的个人信息</span>，并控制隐私权设置，例如：打开或者关闭位置访问、相机、录音和读写手机存储权限；开启或关闭设备智能联动；在大谷云
      “我的 - 设置 - 人脸识别管理” 中控制产品的人脸识别功能开关；登入或登出大谷云账户。若您之前同意我们使用个人信息，可随时通过书面或访问
      https://www.daguiot.com 联系我们改变决定。</p>
    <h3>（二）您对您的个人信息享有的权利</h3>
    <p>根据适用法律，您有权要求访问、更正、删除我们持有的与您相关的个人信息。更多详细信息可通过设备登入帐号访问和更改，其他信息可致信或访问
      https://www.daguiot.com
      联系我们。我们对您的请求有特定要求，包括书面申请、提供足够信息验证身份等。在满足规定的情况下，我们将在适用法律规定的时间内回应您的请求，并可能根据情况收取合理费用。同时，您有权要求更正或完善个人信息，以及在符合规定时要求删除个人数据。我们有权拒绝无实质意义、损害他人隐私权、极端不现实等请求。</p>
    <h3>（三）撤销同意</h3>
    <p>您可以通过提交请求撤销同意，包括收集、使用和 / 或披露我们掌握或控制的您的个人信息。可在应用内通过 “我的”-“用户协议和隐私政策”
      实现，或访问 http://www.daguiot.com 联系我们。我们将在十五天内处理您的请求，此后不再收集、使用和 /
      或披露您的个人信息。但请注意，撤销同意可能导致您不能享受深圳大谷的产品或服务，且不影响此前基于授权而开展的个人信息处理。</p>
    <h3>（四）注销服务或账号</h3>
    <p>如您希望注销具体产品或服务，可访问 http://www.daguiot.com
      联系我们进行服务注销。注销大谷云帐号需谨慎操作，我们会根据您对各产品和服务的使用情况判断是否支持您的注销请求。</p>
    <h3>（五）第三方 SDK 名称及获取的相关信息和使用的目的范围</h3>

    <p>我们的应用可能会使用以下第三方推送服务：</p>
    <p>友盟消息推送 U-Push：运营方：友盟同欣（北京）科技有限公司。<span class="info-collection">共享信息：设备信息（IMEI/MAC/Android ID/IDFA/OAID/OpenUDID/GUID/SIM 卡 IMSI/ICCID）、位置信息、网络信息。</span>
      使用目的：推送设备告警消息。使用场景：接收到设备告警。共享方式：SDK
      本机采集。第三方隐私政策权个人信息处理规则：https://www.umeng.com/page/policy。SDK
      (BuglySDK)：运营方：深圳市腾讯计算机系统有限公司。共享信息：<span class="info-collection">设备标识信息，日志信息联网信息、系统名称、系统版本以及国家码、手机序列号、当前运行程序</span>
      。使用目的：用于对 App 进行崩溃分析。使用场景：使用帮助与反馈功能。共享方式：SDK
      本机采集优化样式。第三方个人信息处理规则：https://privacy.qq.com/document/preview/fc748b3d96224fdb825ea79e132c1a56。
    </p>
      <h3>1. 华为 PUSH</h3>
      <p>第三方公司名称：华为软件技术有限公司。</p>
      <p>共享类型：内嵌 SDK(华为 PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到华为用户。</p>
      <p>使用场景：对华为品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177">https://developer.huawei.com/consumer/cn/doc/development/HMSCore-Guides/sdk-data-security-0000001050042177</a></p>
      <h3>2. 荣耀 PUSH</h3>
      <p>第三方公司名称：荣耀终端有限公司。</p>
      <p>共享类型：内嵌 SDK(荣耀 PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到荣耀用户。</p>
      <p>使用场景：对荣耀品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="https://developer.hihonor.com/cn/kitdoc?category=%E5%9F%BA%E7%A1%80%E6%9C%8D%E5%8A%A1&kitId=11002&navigation=guides&docId=personal-data-processing.md&token=">https://developer.hihonor.com/cn/kitdoc?category=%E5%9F%BA%E7%A1%80%E6%9C%8D%E5%8A%A1&kitId=11002&navigation=guides&docId=personal-data-processing.md&token=</a></p>
      <h3>3. 小米 PUSH</h3>
      <p>第三方公司名称：小米科技有限责任公司。</p>
      <p>共享类型：内嵌 SDK(小米 PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到小米用户。</p>
      <p>使用场景：对小米品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="https://dev.mi.com/console/doc/detail?pId=1822">https://dev.mi.com/console/doc/detail?pId=1822</a></p>
      <h3>4. 魅族 PUSH</h3>
      <p>第三方公司名称：魅族科技有限公司。</p>
      <p>共享类型：内嵌 SDK(魅族 PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到魅族用户。</p>
      <p>使用场景：对魅族品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="http://static.meizu.com/resources/term/privacy8.html">http://static.meizu.com/resources/term/privacy8.html</a></p>
      <h3>5. OPPO PUSH</h3>
      <p>第三方公司名称：OPPO 广东移动通信有限公司。</p>
      <p>共享类型：内嵌 SDK(OPPO PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到 OPPO 用户。</p>
      <p>使用场景：对 OPPO 品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="https://open.oppomobile.com/new/developmentDoc/info?id=10288">https://open.oppomobile.com/new/developmentDoc/info?id=10288</a></p>
      <h3>6. VIVO PUSH</h3>
      <p>第三方公司名称：维沃移动通信有限公司。</p>
      <p>共享类型：内嵌 SDK(VIVO PUSH)。</p>
      <p>共享信息：设备识别信息。</p>
      <p>使用目的：将应用消息推送到 VIVO 用户。</p>
      <p>使用场景：对 VIVO 品牌的手机进行消息推送。</p>
      <p>共享方式：SDK 本机采集。</p>
      <p>第三方个人信息处理规则：<a href="https://dev.vivo.com.cn/documentCenter/doc/652">https://dev.vivo.com.cn/documentCenter/doc/652</a></p>

    <h2>六、联系我们</h2>
    <p>如果您对本隐私政策有任何意见或问题，可通过下方地址或访问 https://www.daguiot.com 联系我们，并提及
      “隐私政策”。我们有专业团队解决您的问题，若对答复不满意，您可以将投诉移交给所在司法辖区的相关监管机构。我们会根据您的实际情况，提供可能适用的投诉途径信息。</p>
    <h2>大谷云APP开发以及运营者相关信息</h2>
      <p>看护家APP是由“深圳市大谷物联科技有限公司”开发。</p>
      <p>"深圳市大谷物联科技有限公司"注册地址位于“深圳市龙岗区坂田街道雅星路8号星河双子塔-西塔1705B”</p>
      <p>"深圳市大谷物联科技有限公司"常用地址位于"深圳市龙岗区坂田街道雅星路8号星河双子塔-西塔1705B"</p>
  </div>
</template>
<script> export default {name: 'PrivacyPolicyComponent',}; </script>
<style scoped> .privacy-policy {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  color: #333;
  line-height: 1.6;
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #eaeaea;
  border-radius: 8px;
  background-color: #fff;
}

.privacy-policy h1 {
  color: #0056b3;
  font-size: 24px;
  margin-bottom: 20px;
}

.privacy-policy h2 {
  color: #0056b3;
  font-size: 20px;
  margin-bottom: 15px;
}

.privacy-policy h3 {
  color: #0056b3;
  font-size: 18px;
  margin-bottom: 10px;
}

.privacy-policy p {
  margin-bottom: 15px;
}

.privacy-policy ul {
  margin-bottom: 15px;
  list-style-type: disc;
  padding-left: 40px;
}

.privacy-policy li {
  margin-bottom: 10px;
}

.info-collection {
  color: #0056b3;
}

.privacy-policy a {
  color: #0056b3;
  text-decoration: none;
}

.privacy-policy a:hover {
  text-decoration: underline;
} </style>
