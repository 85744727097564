<template>
  <div id="HomePage">
    <div class="header-" id="headers">
      <Header></Header>
    </div>
    <!-- 轮播图 -->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide"><!--
            <img class="swiper-lazy" :data-src="item.img" alt="轮播图">-->
            <div class="swiper-lazy"></div>
            <div class="swiper-lazy-preloader"></div>
            <div class="swiper-slide-title">
              <h3 class="gradient-text">我们的产品</h3>
              <p>
                提供全品类包括智能摄像机、智能门铃、智能猫眼、智能摄像机套装等智能硬件设
              </p>
              <p>
                备解决方案,稳定快速安全的 P2P 传输服务以及覆盖多场景应用的App、云端存储
              </p>
              <p>
                等全链路一站式解决方案。拓阔不仅研发出领先的AI算法，还开发了多种智能视觉
              </p>
              <p>
                的AI产品，带来更安全更智能的生活体验。大谷物联开发者团队已经完成了将算法
              </p>
              <p>
                转换为产品的所有工作
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 4G低功耗 -->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning">
          <img class="img-responsive" src="@/assets/img/digonghao4G.png" style="border-radius: 28px">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn animation-right">
          <h1 class="bigData-device">4G低功耗网络摄像机</h1>
          <p>
            我们的4G低功耗网络摄像机是先进的安防设备，4G网络连接，低功耗设计，高画质图像和夜视功能，移动侦测和报警，防水耐用，灵活安装，安全监控的理想选择</p>
          <dl>
            <dt>4G太阳能球机</dt>
            <dd>200万/400万像素、星光夜视、低功耗365天不断电、云台控制、人形识别、人形追踪、声光报警，支持多人同时观看</dd>
            <dd>防护等级：IP66</dd>
            <dd>主要用于：养殖业、种植业等</dd>
          </dl>
          <dl>
            <dt>4G电池机摄像机</dt>
            <dd>200万/400万像素，星光夜视、待机60天、不间断录像48小时、支持多人同时观看</dd>
            <dd>防护等级：IP67</dd>
            <dd>主要用于：野生动物生态保护、户外运动、露营等</dd>
          </dl>
        </div>
      </div>
    </div>

    <!-- 4G -->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn  animation-left">
          <h2 class="bigData-device">4G网络摄像机</h2>
          <p>我们提供基于4G网络摄像机系列产品，具有方便安装，实时传输和智能报警
            等功能。产品无需拉网线或者电线即可实现环境视频监控，适用于各种临时构
            筑物，野外或偏远地区</p>
          <dl>
            <dt>4G常供电球机</dt>
            <dd>200万像素、云台控制、人形识别、人形追踪、声光报警、支持多人同时观看</dd>
            <dd>防护等级：IP66</dd>
            <dd>主要用于：农村住宅、庭院、商铺、工地等</dd>
          </dl>
        </div>

        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning">
          <img class="img-responsive" src="@/assets/img/4G3.png" style="border-radius: 34px">
        </div>
      </div>
    </div>

    <!-- Wi-Fi -->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning">
          <img class="img-responsive" src="@/assets/img/wifi-jie.jpg" style="border-radius: 40px;">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn  animation-right">
          <h2 class="bigData-device">Wi-Fi网络摄像机</h2>
          <p>我们提供基于Wi-Fi连接摄像机系列产品，无需布线即可部署，覆盖居家、小区、办公多场景</p>
          <dl>
            <dt>家用摄像机</dt>
            <dd>200万/400万像素、8米夜视、云台控制、双向语音对讲、报警推送、录像存储</dd>
            <dd>DC 5V供电</dd>
            <dd>主要用于：家庭监控等</dd>
          </dl>
        </div>
      </div>
    </div>
    <!--技术创新优势-->
    <div id="bigData" class="container-fuild">
      <div class="prodectAdvantage___Ss8ws aos-init aos-animate">
        <h4>技术创新优势</h4>
        <div class="advantageList___znX_u">
          <div class="advantageCard___Mukz4"><img src="@/assets/img/img_product_advantage_1.jpg"><h5>P2P传输</h5>
            <p>自研 P2P
              传输服务，具备高稳定性，高并发、低延时、全球化接入能力。单台服务器支持百万级设备连接服务，P2P穿透成功率远超行业水平。引领IPC进入实时智能新时代。</p>
          </div>
          <div class="advantageCard___Mukz4"><img src="@/assets/img/img_product_advantage_2.jpg"><h5>智能AI检测</h5>
            <p>集成全套Toco-Smart云端产品，涵盖家庭安全各种场景的智能服务。搭配云存，为客户提供更多增值服务选项。</p></div>
          <div class="advantageCard___Mukz4"><img src="@/assets/img/img_product_advantage_3.jpg"><h5>工业设计卓越</h5>
            <p>
              极视云设计团队提供独特新颖的工业设计，打破原有安防摄像机的外观印象，提供更亮眼的外观服务，让产品更具竞争力。</p>
          </div>
          <div class="advantageCard___Mukz4"><img src="@/assets/img/img_product_advantage_4.jpg"><h5>安全加密</h5>
            <p>端到端的全链路加密，杜绝安全隐患。配合完善的用户账户/设备安全机制,给您的产品提供全方位的安全保障。</p></div>
        </div>
      </div>

    </div>
    <!-- 您身边的IT专家 -->
    <div id="contactUs" class="container-fuild text-center">
      <div class="container contactUs-container wow slideInUp">
        <h1>合作联系</h1>
        <h3>我们期待与更多合作伙伴共同探索，让生活更智能，让城市更聪慧</h3>
        <button @click="toContactMe"
          class="btn btn-default btn-sm"
          onmouseleave="this.style.borderColor='#ffffff'; this.style.backgroundColor='#ffffff'; this.style.color='#3f3f3f';"
          onmouseenter="this.style.backgroundColor='transparent'; this.style.borderColor='#ffffff'; this.style.color='#ffffff';"
        >联系我们
        </button>
        <div class="contactUs-contactWay">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <Footer ></Footer>

  </div>
</template>
<script>
import Swiper from "swiper";
import {WOW} from 'wowjs';
import router from "../router";

export default {
  name: "AppService",
  methods:{
    toContactMe(){
      router.push("contact")
    }
  },
  mounted() {

    /* banner-swiper */
    new Swiper(".banner-swiper", {
      loop: true, // 循环模式选项
      effect: 'fade',
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
    /* customer-swiper */
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init();
  }

};
</script>
<style scoped>
/*左到右渐入效果*/

.animation-left {
  animation: fadeInLeft 1s ease-in-out forwards;
}
.animation-left.animate {
  opacity: 1;
  transform: translateX(0); /* 最终位置在左侧 */
}
@keyframes fadeInLeft {
  0% {
    transform: translateX(-100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

/*右到左渐入效果*/

.animation-right {
  animation: fadeInRight 1s ease-in-out forwards;
}

@keyframes fadeInRight {
  0% {
    transform: translateX(100%);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
/*技术创新优势*/
.aos-animate {
  opacity: 1;
  transform: translateZ(0);
}

.prodectAdvantage___Ss8ws h4 {
  font-weight: 600;
  font-size: 40px;
  text-align: center;
}

.prodectAdvantage___Ss8ws .advantageList___znX_u {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 40px;
}

.prodectAdvantage___Ss8ws .advantageList___znX_u .advantageCard___Mukz4 p {
  font-size: 14px;
  line-height: 20px;
}

.prodectAdvantage___Ss8ws .advantageList___znX_u .advantageCard___Mukz4 {
  width: 45%;
  margin-bottom: 40px;
}

.prodectAdvantage___Ss8ws .advantageList___znX_u .advantageCard___Mukz4 h5 {
  margin: 16px 0 10px;
  font-weight: 600;
  font-size: 18px;
}

.prodectAdvantage___Ss8ws {

  width: 100%;
  margin-top: 10px;
  margin-bottom: 100px;
  padding: 42px 70px;
  background: #f7f7f7;
  border-radius: 40px;
}

dl {
  margin-top: 30px;
}

dd::before {
  margin: 7px 0 0px;
  content: "●";
  display: inline-block;
  margin-right: 5px;
}

dl dt {
  font-weight: 400;
  color: #1e73be;
}



/* 整体盒子 */
#HomePage {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;

}

.fine-tuning {
  display: flex;
  justify-content: center;
}

/* 轮播图 */
#swiper {
  height: 310px;
}

#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}

#swiper .banner-swiper .swiper-slide {
  position: relative;
}

#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  padding: 3% 0% 0% 20%;
  background: #4162FF;

  line-height: 37px;
}

#swiper .banner-swiper .swiper-slide-title > h1 {
  font-size: 50px;
  margin-top: 12%;
}

#swiper .banner-swiper .swiper-slide-title > p {
  font-size: 14px;
  color: #fff;
  height: 20px;
  margin: auto;

}

/* App产品介绍 */
#bigData {
  padding: 20px 220px;
  transition: all ease 0.6s;
  box-sizing: border-box;
  animation: fadeIn 2s ease-in-out forwards;
}

.row {
  padding: 5% 12%;

}

.gradient-text {
  font-size: 42px;
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: #fff;
  font-weight: bolder;
}

#bigData p {
  font-size: 16px;
  color: #333;
  line-height: 2rem;
}

#bigData .bigData-device {
  font-weight: bolder;
  margin: 0px 0 20px;
}

/* 您身边的IT专家 */
#contactUs {
  color: #fff;
  height: 400px;
  background: url("../assets/img/contact_us_bg.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  transition: all ease 0.6s;
}

#contactUs .contactUs-container {
  padding-top: 50px;
}

#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}

#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(1) {
  background: url("../assets/img/weixin.png") 0 0 no-repeat;
  background-size: 100% 100%;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(2) {
  background: url("../assets/img/weibo.png") 0 0 no-repeat;
  background-size: 100% 100%;
}

#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(3) {
  background: url("../assets/img/twitter.png") 0 0 no-repeat;
  background-size: 100% 100%;
}


/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }

  #bigData {
    padding: 30px;
  }


  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }


  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }

  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }

  #whyChooseUs .server-block {
    padding: 50px 0;
    border: 1px solid #ccc;
    border-bottom: 5px solid #ccc;
  }

  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }

  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }

  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }

  #bigData {
    padding: 60px;
  }

  #bigData .bigData-device {
    margin: 30px 0 15px;
  }

  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }

  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }

  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }

  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }

}
</style>

