<template>
  <div id="HomePage">
    <div class="header-" id="headers">
      <Header></Header>
    </div>
    <!-- 轮播图 -->
    <div id="swiper" class="container-fuild">
      <div class="swiper-container banner-swiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item,index) in swiperList" :key="index"><!--
            <img class="swiper-lazy" :data-src="item.img" alt="轮播图">-->
            <img class="swiper-lazy" src="@/assets/img/shouye.png" alt="轮播图">
            <div class="swiper-lazy-preloader"></div>
            <div class="swiper-slide-title">
                <h1 class="gradient-text">智能视觉产品</h1>
                <p style="color: lightgrey;font-size: 17px;">{{item.content}}</p>
                <p style="color: #31B0D5">基于大谷 AIoT开发平台，极速研发4G、Wi-Fi等多种网络通讯协议的智能化视觉产品</p>
            </div>
          </div>
        </div>
        <!-- 如果需要分页器 --><!--
        <div class="swiper-pagination"></div>-->
<!--
        &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;
        <div class="swiper-button-prev"></div>
        <div class="swiper-button-next"></div>-->
      </div>
    </div>
    <!-- 大数据管理系统 -->
    <div id="bigData" class="container-fuild">
      <div class="row bigData-container">
        <div class="col-xs-12 col-sm-12 col-md-6 wow zoomIn">
          <img class="img-responsive" src="@/assets/img/img1.png" alt="大数据管理系统">
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6">
          <h2 class="bigData-title">
            关于大谷物联
            <small>About DaGuIot</small>
          </h2>
          <p>大谷物联是行业领先的集“物联网视频云平台+产品和解决方案”为一体的创新型公司。作为国家级高新技术企业，公司以“智能视觉”为核心，致力于物联网视频监控设备、人工智能、Toco Cloud智能云服务等多项领域的研发、生产和销售。</p>
            <p>与国内众多厂商不同，大谷物联在成立之初，就把目光瞄向了机器视觉市场。产品聚焦于家庭安防、婴幼儿监护、个人娱乐、健康看护、远程教育、车载等多个领域，并建立自有的全球化云计算系统，其产品先后在全球多地销售，获得用户的广泛认可，为用户提供专业的智能视觉产品和整体解决方案</p>
          <p>作为摄像头行业的领先者，大谷物联提供多种解决方案，涵盖了各种应用场景，包括安防监控、智能家居、商业监控、交通监控等。公司的解决方案旨在满足客户的需求，并提供高清图像质量、智能分析功能、远程访问等先进的功能。</p>
          <p>大谷物联的产品线包括各种类型的摄像头，如网络摄像机、高清摄像机、全景摄像机等。公司的产品以其卓越的性能、可靠性和创新性而闻名。</p>
          <h2 class="bigData-device"></h2>
          <a href="#" @click="toContactMe" class="btn btn-lg btn-block btn-info">联系我们</a>
        </div>
      </div>
    </div>
    <!-- 为什么选择我们 -->
    <div id="whyChooseUs" class="conatiner-fuild">
      <div class="container">
        <div class="whyChooseUs-title text-center">
          <p>为什么选择我们的服务</p>
          <p>THE REASON TO CHOOSING US</p>
        </div>
        <div class="row">
          <div
            class="col-xs-12 col-sm-6 col-md-3 server-wrapper"
            v-for="(item,index) in serverList"
            :key="index"
          >
            <div
              class="server-block wow slideInUp"
              onmouseenter="this.style.color='#28f';this.style.borderColor='#28f'"
              onmouseleave="this.style.color='#666';this.style.borderColor='#ccc'"
            >
              <img class="center-block" :src="item.logo" alt="logo">
              <p class="text-center">{{item.title}}</p>
              <div
                class="text-center"
                v-html="item.content"
                style="color: #666"
                onmouseenter="this.style.color='#28f'"
                onmouseleave="this.style.color='#666'"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 合作商 -->
    <div id="customer" class="container-fuild">
      <div class="container customer-container">
        <p class="customer-title text-center">合作伙伴</p>
        <div class="swiper-container customer-swiper hidden-xs">
          <div class="swiper-wrapper">
            <div
              class="swiper-slide customer-block"
              v-for="(item,index) in customerList"
              :key="index"
            >
              <div class="customer-logo">
                <img class="center-block" :src="item.logo" alt="logo">
              </div><!--
              <div class="customer-yh">
                <img src="@/assets/img/yinhao.png" alt="引号">
              </div>
              <div class="customer-content1">
                <small style="font-size: 14px;">{{item.content}}</small>
              </div>
              <div class="customer-content2" style="font-size: 16px;font-weight: bolder">{{item.title}}</div>-->
            </div>
          </div><!--
          &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>-->
        </div>
        <div class="row visible-xs customer-block">
          <div class="col-xs-12" v-for="(item,index) in customerList" :key="index">
            <div class="customer-logo">
              <img class="center-block" :src="item.logo" alt="logo">
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 您身边的IT专家 -->
    <div id="contactUs" class="container-fuild text-center">
      <div class="container contactUs-container wow slideInUp">
        <h1>合作联系</h1>
        <h3>我们期待与更多合作伙伴共同探索，让生活更智能，让城市更聪慧</h3>
        <button
          @click = "toContactMe"
          class="btn btn-default btn-sm"
          onmouseleave="this.style.borderColor='#ffffff'; this.style.backgroundColor='#ffffff'; this.style.color='#3f3f3f';"
          onmouseenter="this.style.backgroundColor='transparent'; this.style.borderColor='#ffffff'; this.style.color='#ffffff';"
        >联系我们</button>
        <div class="contactUs-contactWay">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <Footer ></Footer>

  </div>

</template>
<script>
import Swiper from "swiper";
import { WOW } from 'wowjs';
import router from "../router";
export default {
  name: "HomePage",
  methods:{
    toContactMe(){
      router.push("contact")
    }
  },
  data() {
    return {
      swiperList: [
        {
          path: "",
          title: 'Toco-Cloud云服务',
          content: '快速构建和部署智能化系统',
        },
        {
          path: "",
          title: 'AI+算法',
          content: '智能分析图像声音，实现精细管控',
        }
      ],
      customerList: [
        {
          logo: require("@/assets/img/brand/haisi.jpg"),
          title:
            "海思",
          content:
            "xxx"
        },
        {
          logo: require("@/assets/img/brand/guokehui.jpg"),
          title:
            "国科微",
          content:
            "XXX"
        },
        {
          logo: require("@/assets/img/brand/yiyuantonxin.jpg"),
          title:
            "移远通信",
          content:
            "xxx"
        },
        {
          logo: require("@/assets/img/brand/guanghetong.jpg"),
          title:
            "广和通",
          content:
            "xxx"
        },
        {
          logo: require("@/assets/img/brand/sitewei.jpg"),
          title:
            "斯特威电子科技",
          content:
            "xxx"
        },
        {
          logo: require("@/assets/img/brand/dianxin.jpg"),
          title:
            "中国电信",
          content:
            "xxx"
        },
        {
          logo: require("@/assets/img/brand/yidong.jpg"),
          title:
            "中国移动",
          content:
            "xxx"
        }
      ],
      serverList: [
        {
          logo: require("@/assets/img/live.png"),
          title: "智慧生活",
          content: "<p>实时监控家庭安全，远程访问，</p>智能警报，保护您的家人和财产。"
        },
        {
          logo: require("@/assets/img/niu.png"),
          title: "智慧生产",
          content: "<p>实现对林牧渔业环境的智能监测,</p>提高管理水平,推动养殖业智能化。"
        },
        {
          logo: require("@/assets/img/yundongjiankang.png"),
          title: "智慧健康",
          content: "<p>健康监测，远程护理，智能诊断，</p>健康管理，关心您的身心健康。"
        },
        {
          logo: require("@/assets/img/business.png"),
          title: "智慧商业",
          content: "<p>客流统计，智能分析，安防监控，</p>优化运营效率，增加商业价值。"
        }
      ]
    };
  },
  mounted() {
    /* banner-swiper */
    new Swiper(".banner-swiper", {
      loop: true, // 循环模式选项
      effect: 'fade',
      //自动播放
      autoplay: {
        delay: 3000,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要分页器
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      // 延迟加载
      lazy: {
        loadPrevNext: true
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
    /* customer-swiper */
    new Swiper(".customer-swiper", {
      loop: true, // 循环模式选项
      slidesPerView: 3,
      //自动播放
      autoplay: {
        delay: 900,
        stopOnLastSlide: false,
        disableOnInteraction: false
      },
      // 如果需要前进后退按钮
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      observer: true, //修改swiper自己或子元素时，自动初始化swiper
      observeParents: true //修改swiper的父元素时，自动初始化swiper
    });
    /* wowjs动画 */
    var wow = new WOW({
      boxClass: 'wow',
      animateClass: 'animated',
      offset: 0,
      mobile: true,
      live: true
    })
    wow.init();
  }
};
</script>
<style scoped>
/* 整体盒子 */
#HomePage {
  width: 100%;
  max-width: 1900px;
  margin: 0 auto;
}
/* 轮播图 */
#swiper {
  height: 600px;
}
#swiper .banner-swiper {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide img {
  width: 100%;
  height: 100%;
}
#swiper .banner-swiper .swiper-slide{
  position: relative;
}
#swiper .banner-swiper .swiper-slide-title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999999;
  width: 100%;
  height: 100%;
  color: #fff;
  background: rgba(223, 255, 255, 0.114);
  text-align: center;
  line-height: 80px;
}
.btn-info{
  background-color: #1e73be;
  border-color: #1e73be;
}
#swiper .banner-swiper .swiper-slide-title > h1{
  font-size: 50px;
  margin-top: 12%;
}
#swiper .banner-swiper .swiper-slide-title > p{
  font-size: 20px;
  margin-top: 1%;
  font-weight: 700;
}
/* 大数据管理系统 */
#bigData {
  padding: 100px;
  transition: all ease 0.6s;
  box-sizing: border-box;
}
#bigData .bigData-title {
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.gradient-text {
  background-image: linear-gradient(to right, #000066 , #00ff00);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  color: transparent;
}
#bigData p {
  font-size: 15px;
  color: #333;
  line-height: 2rem;
}
#bigData .bigData-device {
  margin: 50px 0 20px;
}

/* 您身边的IT专家 */
#contactUs {
  color: #fff;
  height: 400px;
  background: url("../assets/img/contact_us_bg.jpg") 0 0 no-repeat;
  background-size: 100% 100%;
  transition: all ease 0.6s;
}
#contactUs .contactUs-container {
  padding-top: 50px;
}
#contactUs .contactUs-container button {
  width: 300px;
  height: 50px;
  margin-top: 40px;
}
#contactUs .contactUs-container .contactUs-contactWay span {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin: 30px;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(1) {
  background: url("../assets/img/weixin.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(2) {
  background: url("../assets/img/weibo.png") 0 0 no-repeat;
  background-size: 100% 100%;
}
#contactUs .contactUs-container .contactUs-contactWay span:nth-of-type(3) {
  background: url("../assets/img/twitter.png") 0 0 no-repeat;
  background-size: 100% 100%;
}

/* 客户评价 */
#customer {
  padding: 50px 0;
  box-sizing: border-box;
  background: #efefef;
  transition: all ease 0.6s;
}
#customer .customer-title {
  font-size: 30px;
  color: rgb(102, 102, 102);
  margin: 0 0 30px;
}
#customer .customer-block {
  background: #fff;
  padding: 30px;
}
#customer .customer-logo img {

}
#customer .customer-yh img {
  width: 34px;
  height: 34px;
}
#customer .customer-content1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #8c8c8c;
}
#customer .customer-content2 {

  padding-top: 20px;
}
/* 为什么选择我们 */
#whyChooseUs {
  padding: 100px;
}
#whyChooseUs .whyChooseUs-title {
  margin-bottom: 50px;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
  font-size: 25px;
  font-weight: 500;
}
#whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
  font-size: 14px;
}
#whyChooseUs .server-block {
  padding: 50px 20px;
  border: 1px solid #ccc;
  border-bottom: 5px solid #ccc;
}
#whyChooseUs .server-block img {
  width: 48px;
  height: 48px;
}
#whyChooseUs .server-block > p {
  font-size: 20px;
  margin: 30px 0;
}
#whyChooseUs .server-block > div {
  color: #ccc;
}
/* 媒体查询（手机） */
@media screen and (max-width: 768px) {
  #swiper {
    height: 200px;
  }
  #bigData {
    padding: 30px;
  }
  #bigData .bigData-title {
    font-size: 20px;
  }
  #bigData .bigData-device {
    font-size: 20px;
    margin: 10px 0 10px;
  }
  #contactUs {
    height: 200px;
    transition: all ease 0.6s;
  }
  #contactUs .contactUs-container {
    padding-top: 0;
  }
  #contactUs .contactUs-container h1 {
    font-size: 25px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 18px;
  }
  #contactUs .contactUs-container button {
    width: 200px;
    height: 30px;
    margin-top: 20px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 28px;
    height: 28px;
    margin: 10px;
  }
  #customer {
    padding: 30px 0;
    box-sizing: border-box;
    background: #fff;
  }
  #customer .customer-title {
    font-size: 16px;
    font-weight: bold;
  }
  #customer .customer-logo img {
    width: 48px;
    height: 48px;
  }
  #customer .customer-block {
    padding: 30px;
  }
  #customer .customer-block > div {
    padding: 30px 0;
  }
  #whyChooseUs {
    padding: 20px 0;
    transition: all ease 0.6s;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(1) {
    font-size: 20px;
    font-weight: 700;
  }
  #whyChooseUs .whyChooseUs-title p:nth-of-type(2) {
    font-size: 12px;
  }
  #whyChooseUs .server-block {
    padding: 50px 0;
    border: 1px solid #ccc;
    border-bottom: 5px solid #ccc;
  }
  #whyChooseUs .server-block img {
    width: 48px;
    height: 48px;
  }
  #whyChooseUs .server-block > p {
    font-size: 20px;
    margin: 30px 0;
  }
  #whyChooseUs .server-block > div {
    color: #ccc;
  }
}

/* 媒体查询（平板） */
@media screen and (min-width: 768px) and (max-width: 996px) {
  #swiper {
    height: 400px;
  }
  #bigData {
    padding: 60px;
  }
  #bigData .bigData-title {
    font-size: 30px;
  }
  #bigData .bigData-device {
    font-size: 30px;
    margin: 30px 0 15px;
  }
  #contactUs {
    height: 300px;
  }
  #contactUs .contactUs-container {
    padding-top: 50px;
  }
  #contactUs .contactUs-container h1 {
    font-size: 30px;
  }
  #contactUs .contactUs-container h3 {
    font-size: 20px;
  }
  #contactUs .contactUs-container button {
    width: 300px;
    height: 50px;
    margin-top: 30px;
  }
  #contactUs .contactUs-container .contactUs-contactWay span {
    display: inline-block;
    width: 32px;
    height: 32px;
    margin: 15px;
  }
  #customer .customer-title {
    font-size: 24px;
  }
  #whyChooseUs {
    padding: 20px 0;
  }
}
</style>

