<template>
  <!-- 头部整体盒子 -->
  <div id="header" class="container-fuild" style="box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2)">

    <!-- 电脑导航 -->
    <div class="header-nav container hidden-xs">
      <!-- 导航logo -->
      <div class="header-nav-logo" @click="home()">
        <img src="@/assets/img/logo.png">
      </div>
      <!-- 导航内容 -->
      <ul class="header-nav-wrapper">
        <li
          v-for="(item,index) in navList"
          :key="index"
          :class="''"
          @click="navClick(index)"
        >
          <router-link :to="item.path">
            {{item.name}}
            <span v-if="item.children.length>0" class="glyphicon glyphicon-menu-down"></span>
            <i class="underline"></i>
          </router-link>
          <dl v-if="item.children.length>0">
            <dt v-for="(i,n) in item.children" :key="n">
              <router-link :to={path:i.path,hash:i.hash} style="text-decoration: none">{{i.name}}</router-link>
            </dt>
          </dl>
        </li>
        <li><a class="login"
               href="http://system.daguiot.com:8000"
               target="_blank"
        >登录</a></li>
      </ul>
    </div>
    <!-- 手机导航 -->
    <div class="header-nav-m container-fuild visible-xs">

      <!-- 导航栏 -->
      <div class="header-nav-m-menu text-center">
        <div
          class="header-nav-m-menu-wrapper"
          data-toggle="collapse"
          data-target="#menu"
          @click="menuClick"
        >
          <span :class="menuClass">
              <svg t="1700299548951" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="11565" width="37" height="37"><path d="M135.68 176.128h752.128v136.704H135.68V176.128z m0 273.92h752.128v136.704H135.68V450.048z m0 273.408h752.128V860.16H135.68v-136.704z" fill="#757575" p-id="11566"></path></svg>
          </span>
        </div>
        <!-- 导航内容 -->
        <ul id="menu" class="header-nav-m-wrapper collapse">
          <li
            v-for="(item,index) in navList"
            :key="index"
            :class="index==navIndex?'active':''"
            @click="navClick(index)"
            data-toggle="collapse"
            data-target="#menu"
          >
            <router-link :to={path:item.path,hash:item.hash} style="text-decoration: none">
              {{item.name}}
              <i class="underline"></i>
            </router-link>
          </li>
          <li class="login-phone">登录</li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>

import router from "../router";

export default {
  name: "Header",
  data() {
    return {
      navIndex: sessionStorage.getItem('navIndex') ? sessionStorage.getItem('navIndex') : 0,
      menuClass: "glyphicon",
      navList: [
        {
          name: "首页",
          path: "/",
          children: []
        },
        {
          name: "解决方案",
          path: "/software",
          children: [
            {
              name: "行业解决方案",
              path: "/software",
              hash:"target-jie"
            },

            {
              name: "AI算法",
              path: "/software",
              hash: "target-Ai"
            },
            {
              name: "Toco-Cloud云服务",
              path: "/software",
              hash: "target-cloud"
            }

          ]
        },
        {
          name: "产品",
          path: "/service",
          children: []
        },
        {
          name: "App",
          path: "/newsinformation",
          children: []
        },
        {
          name: "关于我们",
          path: "/companyintroduction",
          children: []
        },
        {
          name: "联系我们",
          path: "/contact",
          children: [/*{
            name: "技术支持",
            path: "/support"
          }*/]
        },
      ]
    };
  },
  methods: {
    router() {
      return router
    },
    home(){
      router.push("/")
    },
    navClick(index) {
      this.navIndex = index;
      sessionStorage.setItem('navIndex',index)

    },
    menuClick() {
      if (this.menuClass == "glyphicon") {
        this.menuClass = "glyphicon";
      } else {
        this.menuClass = "glyphicon";
      }
    }
  }
};
</script>
<style scoped>
#header .header-nav .header-nav-wrapper > li > .login{
  left: 290px;
  font-size: 15px;
  /*border: solid 1px #EBEBEB;*/
  padding: 11px 35px;/*
  border-radius: 10px;*/
  text-decoration: none;/*
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);*/
}
#header .header-nav .header-nav-wrapper > li > .login:active{
  text-decoration: none;
  color: #1e73be;
}
#header .header-nav .header-nav-wrapper > li > .login:hover{
  transition: all .3s;
  text-decoration: none;
  /*border: #1e73be 1px solid;*/
  color: #1e73be;
}
.login-phone{
  font-size: 16px;
}
/* 顶部 */
#header {
  background: rgba(255,255,255,0.9);
  transition: all ease 0.6s;
  position: fixed;
  width: -webkit-fill-available;
  z-index: 1000;
}
#header .header-top {
  height: 50px;
  color: #fff;
  font-size: 12px;
  line-height: 50px;
  background: #474747;
}
/* 顶部的图标 */
#header .header-top span {
  margin: 0 8px;
}
/* 导航栏 */
#header .header-nav {
  height: 90px;
}
/* 导航栏logo */
#header .header-nav .header-nav-logo {
  width: 100px;
  height: 100%;
  float: left;
  position: relative;
}
/* 导航栏logo图片 */
#header .header-nav .header-nav-logo img {
  width: auto;
  height: 45px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
/* 导航栏 导航容器 */
#header .header-nav-fixed .header-nav-wrapper {
  line-height: 50px;
}
#header .header-nav .header-nav-wrapper {
  line-height: 89px;
  float: right;
  margin: 0;
  max-width: 800px;
}
/* 导航栏 每个导航 */
#header .header-nav .header-nav-wrapper > li {
  float: left;
  margin: 0 0 0 45px;
  position: relative;
}
a{
  color: black;
}

/* 导航栏 每个导航下面的 a 链接 */
#header .header-nav .header-nav-wrapper > li > a {
  color: #000;
  font-size: 15px;
  font-weight: normal;
  padding: 15px 0;
  position: relative;
}
/* 导航栏 每个导航下面的 a 链接的下划线 */
#header .header-nav .header-nav-wrapper > li > a > i {
  display: block;
  position: absolute;
  bottom: -2px;
  left: 50%;
  width: 0;
  height: 2px;
  opacity: 0;
  transition: all 0.6s ease;
  background-color: #1e73be;
}
/* 导航栏 每个导航下面的 a 链接的右侧小三角 */
#header .header-nav .header-nav-wrapper > li > a > span {
  font-size: 12px;
  transition: transform ease 0.5s;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover {
  color: #1e73be;
  text-decoration: none;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去下划线的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover .underline {
  opacity: 1;
  width: 100%;
  left: 0;
}
/* 导航栏 每个导航下面的 a 链接 鼠标滑上去三角标的样式 */
#header .header-nav .header-nav-wrapper > li > a:hover span {
  transform: rotate(180deg);
}
/* 导航栏 每个导航下面的 a 链接 鼠标点击后的样式 */
#header .header-nav .header-nav-wrapper > li.active > a {
  color: #1e73be;
  text-decoration: none;
  border-bottom: 2px solid #1e73be;
}
/* 导航栏 每个导航下面的二级导航容器 */
#header .header-nav .header-nav-wrapper > li > dl {
  display: none;
  position: absolute;
  width: 168px;
  top: 90%;
  text-align: center;
  left: -50px;
  z-index: 999999;
  border-radius: 8%;
  box-shadow: 0 0 3px 1px #ccc;
  background: #fff;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 */
#header .header-nav .header-nav-wrapper > li > dl > dt {
  width: 100%;
  height: 45px;
  padding: 10px;
}
dt{
  line-height: 1.8;
}
/* 导航栏 每个导航下面的二级导航容器的每个导航 当鼠标滑上时的样式*/
#header .header-nav .header-nav-wrapper > li > dl > dt > a:hover {
  text-decoration: none;
}
/* 导航栏 滑上一级导航显示二级导航 */
#header .header-nav .header-nav-wrapper > li:hover dl {
  display: block;
}
#header .header-nav .header-nav-wrapper > li > dl > dt:hover {
  cursor: pointer;
  border-radius: 8px;
  background: #ccc;
}
@media screen and (max-width: 997px) {
  #header .header-nav-m {
    position: relative;
  }
  /* 导航栏logo容器 */
  #header .header-nav-m .header-nav-m-logo {
    height: 0;
    position: relative;
  }
  /* 导航栏logo图片 */
  #header .header-nav-m .header-nav-m-logo img {
    width: 0;
    height: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }
  /* 导航栏  菜单容器 */
  #header .header-nav-m .header-nav-m-menu {
    color: #000000;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    background: rgba(255,255,255,0.1);
    position: relative;
  }
  /* 导航栏 菜单图标 */
  #header .header-nav-m .header-nav-m-menu-wrapper {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -20px;
    width: 50px;
    height: 40px;
    color: black;
    z-index: 999999;
    font-size: 12px;
  }
  /* 导航栏 */
  #header .header-nav-m .header-nav-m-wrapper {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 9999999;
  }
  /* 导航栏 每个导航 */
  #header .header-nav-m .header-nav-m-wrapper > li {
    height: 40px;
    line-height: 40px;
  }
  /* 导航栏 每个导航下面的 a 链接 */
  #header .header-nav-m .header-nav-m-wrapper > li > a {
    color: black;
    font-size: 15px;
    padding: 15px 0;
    position: relative;
  }
  /* 导航栏 每个导航下面的 a 链接的右侧小三角 */
  #header .header-nav .header-nav-wrapper > li > a > span {
    font-size: 10px;
  }
}
</style>
