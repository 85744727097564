<template>
    <div id="container">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'PageView'
}
</script>
<style scoped>

</style>

