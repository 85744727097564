var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"HomePage"}},[_c('div',{staticClass:"header-",attrs:{"id":"headers"}},[_c('Header')],1),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"container-fuild text-center",attrs:{"id":"contactUs"}},[_c('div',{staticClass:"container contactUs-container wow slideInUp"},[_c('h1',[_vm._v("合作联系")]),_c('h3',[_vm._v("我们期待与更多合作伙伴共同探索，让生活更智能，让城市更聪慧")]),_c('button',{staticClass:"btn btn-default btn-sm",attrs:{"onmouseleave":"this.style.borderColor='#ffffff'; this.style.backgroundColor='#ffffff'; this.style.color='#3f3f3f';","onmouseenter":"this.style.backgroundColor='transparent'; this.style.borderColor='#ffffff'; this.style.color='#ffffff';"},on:{"click":_vm.toContactMe}},[_vm._v("联系我们 ")]),_vm._m(5)])]),_c('Footer')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"swiper"}},[_c('div',{staticClass:"swiper-container banner-swiper"},[_c('div',{staticClass:"swiper-wrapper"},[_c('div',{staticClass:"swiper-slide"},[_c('div',{staticClass:"swiper-lazy"}),_c('div',{staticClass:"swiper-lazy-preloader"}),_c('div',{staticClass:"swiper-slide-title"},[_c('h3',{staticClass:"gradient-text"},[_vm._v("我们的产品")]),_c('p',[_vm._v(" 提供全品类包括智能摄像机、智能门铃、智能猫眼、智能摄像机套装等智能硬件设 ")]),_c('p',[_vm._v(" 备解决方案,稳定快速安全的 P2P 传输服务以及覆盖多场景应用的App、云端存储 ")]),_c('p',[_vm._v(" 等全链路一站式解决方案。拓阔不仅研发出领先的AI算法，还开发了多种智能视觉 ")]),_c('p',[_vm._v(" 的AI产品，带来更安全更智能的生活体验。大谷物联开发者团队已经完成了将算法 ")]),_c('p',[_vm._v(" 转换为产品的所有工作 ")])])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"row bigData-container"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning"},[_c('img',{staticClass:"img-responsive",staticStyle:{"border-radius":"28px"},attrs:{"src":require("@/assets/img/digonghao4G.png")}})]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn animation-right"},[_c('h1',{staticClass:"bigData-device"},[_vm._v("4G低功耗网络摄像机")]),_c('p',[_vm._v(" 我们的4G低功耗网络摄像机是先进的安防设备，4G网络连接，低功耗设计，高画质图像和夜视功能，移动侦测和报警，防水耐用，灵活安装，安全监控的理想选择")]),_c('dl',[_c('dt',[_vm._v("4G太阳能球机")]),_c('dd',[_vm._v("200万/400万像素、星光夜视、低功耗365天不断电、云台控制、人形识别、人形追踪、声光报警，支持多人同时观看")]),_c('dd',[_vm._v("防护等级：IP66")]),_c('dd',[_vm._v("主要用于：养殖业、种植业等")])]),_c('dl',[_c('dt',[_vm._v("4G电池机摄像机")]),_c('dd',[_vm._v("200万/400万像素，星光夜视、待机60天、不间断录像48小时、支持多人同时观看")]),_c('dd',[_vm._v("防护等级：IP67")]),_c('dd',[_vm._v("主要用于：野生动物生态保护、户外运动、露营等")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"row bigData-container"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn animation-left"},[_c('h2',{staticClass:"bigData-device"},[_vm._v("4G网络摄像机")]),_c('p',[_vm._v("我们提供基于4G网络摄像机系列产品，具有方便安装，实时传输和智能报警 等功能。产品无需拉网线或者电线即可实现环境视频监控，适用于各种临时构 筑物，野外或偏远地区")]),_c('dl',[_c('dt',[_vm._v("4G常供电球机")]),_c('dd',[_vm._v("200万像素、云台控制、人形识别、人形追踪、声光报警、支持多人同时观看")]),_c('dd',[_vm._v("防护等级：IP66")]),_c('dd',[_vm._v("主要用于：农村住宅、庭院、商铺、工地等")])])]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning"},[_c('img',{staticClass:"img-responsive",staticStyle:{"border-radius":"34px"},attrs:{"src":require("@/assets/img/4G3.png")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"row bigData-container"},[_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn fine-tuning"},[_c('img',{staticClass:"img-responsive",staticStyle:{"border-radius":"40px"},attrs:{"src":require("@/assets/img/wifi-jie.jpg")}})]),_c('div',{staticClass:"col-xs-12 col-sm-12 col-md-6 wow zoomIn animation-right"},[_c('h2',{staticClass:"bigData-device"},[_vm._v("Wi-Fi网络摄像机")]),_c('p',[_vm._v("我们提供基于Wi-Fi连接摄像机系列产品，无需布线即可部署，覆盖居家、小区、办公多场景")]),_c('dl',[_c('dt',[_vm._v("家用摄像机")]),_c('dd',[_vm._v("200万/400万像素、8米夜视、云台控制、双向语音对讲、报警推送、录像存储")]),_c('dd',[_vm._v("DC 5V供电")]),_c('dd',[_vm._v("主要用于：家庭监控等")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-fuild",attrs:{"id":"bigData"}},[_c('div',{staticClass:"prodectAdvantage___Ss8ws aos-init aos-animate"},[_c('h4',[_vm._v("技术创新优势")]),_c('div',{staticClass:"advantageList___znX_u"},[_c('div',{staticClass:"advantageCard___Mukz4"},[_c('img',{attrs:{"src":require("@/assets/img/img_product_advantage_1.jpg")}}),_c('h5',[_vm._v("P2P传输")]),_c('p',[_vm._v("自研 P2P 传输服务，具备高稳定性，高并发、低延时、全球化接入能力。单台服务器支持百万级设备连接服务，P2P穿透成功率远超行业水平。引领IPC进入实时智能新时代。")])]),_c('div',{staticClass:"advantageCard___Mukz4"},[_c('img',{attrs:{"src":require("@/assets/img/img_product_advantage_2.jpg")}}),_c('h5',[_vm._v("智能AI检测")]),_c('p',[_vm._v("集成全套Toco-Smart云端产品，涵盖家庭安全各种场景的智能服务。搭配云存，为客户提供更多增值服务选项。")])]),_c('div',{staticClass:"advantageCard___Mukz4"},[_c('img',{attrs:{"src":require("@/assets/img/img_product_advantage_3.jpg")}}),_c('h5',[_vm._v("工业设计卓越")]),_c('p',[_vm._v(" 极视云设计团队提供独特新颖的工业设计，打破原有安防摄像机的外观印象，提供更亮眼的外观服务，让产品更具竞争力。")])]),_c('div',{staticClass:"advantageCard___Mukz4"},[_c('img',{attrs:{"src":require("@/assets/img/img_product_advantage_4.jpg")}}),_c('h5',[_vm._v("安全加密")]),_c('p',[_vm._v("端到端的全链路加密，杜绝安全隐患。配合完善的用户账户/设备安全机制,给您的产品提供全方位的安全保障。")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"contactUs-contactWay"},[_c('span'),_c('span'),_c('span')])
}]

export { render, staticRenderFns }